<template>
  <div>
    <div>
      <b-row>
        <b-col
          md="12"
          lg="10"
          xl="8"
        >
          <b-card class="mb-3">
            <b-embed
              type="iframe"
              aspect="16by9"
              :src="serviceYTUrls[serviceName]"
              allowfullscreen
            />
          </b-card>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import {
  BCard,
  BCol,
  BRow,
  BEmbed,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BEmbed,
  },
  directives: {
    Ripple,
  },
  props: [
    'serviceName',
  ],
  data() {
    return {
      serviceYTUrls: {
        wordpress: 'https://www.youtube.com/embed/FnXsFSnvTNk',
        wix: 'https://www.youtube.com/embed/CJk1QSBKJGM',
        squarespace: 'https://www.youtube.com/embed/vYd7nDvqaZI',
        weebly: 'https://www.youtube.com/embed/VlhoADGzMxQ',
        churchco: 'https://youtube.com/embed/2gPIHfpvD4g',
      },
    }
  },
}
</script>
<style scoped>
.card {
  min-width: 175px;
}
</style>
